// window.whBase = require('@mod-system/js/compat/base');

// general JS (always add)
import '@mod-mijnsiteonline/nodejs/mso';

// main CSS
import './web/css/rtd.css'; // cannot be SCSS
import './lopik.scss';
import 'font-awesome/css/font-awesome.min.css';

// template specific JS files
window.$ = require('jquery');
window.jQuery = $;
require('./web/js/jquery.dropotron.min.js');
window.skel = require('./web/js/skel.min.js');
require('./web/js/util.js');
require('./web/js/main.js');
