(function($) {

  skel.breakpoints({
    normal: '(max-width: 1280px)',
    narrow: '(max-width: 1080px)',
    narrower: '(max-width: 880px)',
    mobile: '(max-width: 768px)'
  });

  $(function() {

    var $window = $(window),
      $body = $('body');

    // Prioritize "important" elements on mobile.
      // skel.on('+mobile -mobile', function() {
      //   $.prioritize(
      //     '.important\\28 mobile\\29',
      //     skel.breakpoint('mobile').active
      //   );
      // });

    // Dropdowns.
      $('#nav > ul').dropotron({
        offsetY: -11,
        noOpenerFade: true,
        alignment: 'center'
      });

    // Off-Canvas Navigation.

      // Title Bar.
        $(
          '<div id="titleBar">' +
            '<a href="#navPanel" class="toggle"></a>' +
          '</div>'
        )
          .appendTo($body);

      // Navigation Panel.
      // add 'home' link to panel if it doesn't already exist in the main menu
      var homeInMenu = false;
      // $('#nav a.menulink--main.menulink--text').each(function() {
      //   if ($(this).attr('href') === window.whBase.config.siteroot) {
      //     homeInMenu = true;
      //     return;
      //   }
      // });

        $(
          '<div id="navPanel">' +
            '<nav>' +
              (homeInMenu ? '' : '<a href="index.html" class="link depth-0">Home</a>') +
              $('#nav').navList() +
            '</nav>' +
          '</div>'
        )
          .appendTo($body)
          .panel({
            delay: 500,
            hideOnClick: true,
            hideOnSwipe: true,
            resetScroll: true,
            resetForms: true,
            side: 'left',
            target: $body,
            visibleClass: 'navPanel-visible'
          });

      // Fix: Remove transitions on WP<10 (poor/buggy performance).
        if (skel.vars.os == 'wp' && skel.vars.osVersion < 10)
          $('#titleBar, #navPanel, #page-wrapper')
            .css('transition', 'none');

  });

})(jQuery);
